document.addEventListener('DOMContentLoaded', function() {
    const imageSlider = document.querySelector('.js-image-slider');
    if(!imageSlider) return;

    const slides = imageSlider.querySelectorAll('.js-image-slide');

    let index = 0;

    setInterval(function() {
        slides.forEach(function(slide) {
            slide.classList.remove('is-active');
        });

        index++;

        slides[index % slides.length].classList.add('is-active');

    }, 8000);
});